
import { defineComponent, getCurrentInstance, ref } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import ContactsForm from '@/views/crm/client/components/ViewContact/form.vue';
import useListData from './components/useRecyclebinListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading, errorMessage, successMessage } from '@/utils/publicMethods';
import router from '@/router';
import { httpPost } from '@/api';
export default defineComponent({
  name: 'RecyclebinList',
  components: { CrmList, ContactsForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType } = useVisible();
    const rowData = ref({});
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const setFormType = (type: number): void => {
      formType.value = type;
    };
    const setRowData = (row) => {
      rowData.value = row;
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable();
      }
    };
    /** 还原 */
    const doReturnFn = async (row) => {
      const params = {
        operateType: 2, // 操作类型 1.删除 2.还原
        id: row.id,
        customerId: row.customerId,
        version: row.version,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/customer/deReContacts', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('还原成功');
        refreshTable();
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      getLoadPage,
      rowData,
      setRowData,
      doReturnFn,
    };
  },
});
