import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crm_list = _resolveComponent("crm-list")!
  const _component_ContactsForm = _resolveComponent("ContactsForm")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_crm_list, {
      url: "/malicrm/customer/contactsList",
      filterOption: _ctx.filterOptions,
      columOptions: _ctx.columnList,
      customParams: { listType: 2 },
      ref: "tableRef",
      id: "recyclebinList"
    }, {
      header: _withCtx(() => [
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshTable(false)))
        }, "刷新"),
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getLoadPage('/contacts/contactsList')))
        }, "返回")
      ]),
      _: 1
    }, 8, ["filterOption", "columOptions"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible,
      erp: "",
      onClose: _ctx.closeVisible
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ContactsForm, {
          formType: _ctx.formType,
          data: _ctx.rowData,
          eventType: 1,
          eventId: _ctx.rowData.customerId,
          eventData: _ctx.rowData,
          onClose: _ctx.closeVisible
        }, null, 8, ["formType", "data", "eventId", "eventData", "onClose"])
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ], 64))
}